((document) => {
    const contactTemplatePath = './contact.html';

    let containerEl, btnContactEl;

    const showContact = (content) => {
        const contactEl = document.createRange().createContextualFragment(content);
        containerEl.replaceChild(contactEl, btnContactEl);
    };

    const createFormData = (data) => {
        const fd = new FormData();

        Object.keys(data).forEach((key) => fd.append(key, data[key]));

        return fd;
    };



    const verifyLoad = async (token) => {
        const body = createFormData({ token });
        const params = new URLSearchParams({ token });

        await fetch('api/captcha', {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: params,
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.text();
                }

                alert(
                    [
                        'The system has flagged you as a bot.',
                        "This may be due to restrictive privacy settings in your browser or you're using some kind of script blocking extension/application.",
                        'Contact is unavailable at the moment!',
                    ].join('\n')
                );

                return false;
            })
            .then((content) => {
                btnContactEl.classList.remove('loading');

                if (content) {
                    showContact(content);
                }
            });
    };

    const clickLoad = async () => {
        btnContactEl.classList.add('loading');

        grecaptcha.enterprise.ready(async () => {
            const token = await grecaptcha.enterprise.execute('6LdZpBsqAAAAAEN4qHN2M0kciyWQk-_18UOCzD-q', {
                action: 'submit',
            });

            verifyLoad(token);
        });
    };

    const subscribe = (evt, el, fn) => {
        if (el.addEventListener) {
            el.addEventListener(evt, fn, false);
        } else if (el.attachEvent) {
            el.attachEvent(`on${evt}`, fn);
        } else {
            el[`on${evt}`] = fn;
        }
    };

    document.addEventListener(
        'DOMContentLoaded',
        () => {
            containerEl = document.querySelector('.links');
            btnContactEl = document.getElementById('contact');

            subscribe('touchstart', btnContactEl, clickLoad);
            subscribe('click', btnContactEl, clickLoad);
        },
        { once: true }
    );
})(document);
